var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isFetchingData ? _c('div', {
    staticClass: "SavingsCalculator-Tables"
  }, [_c('div', {
    staticClass: "SavingsCalculator-TableFilters"
  }, [_c('Toolbar', [_c('div', {
    staticClass: "TableFilters-Controls"
  }, [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.employeesOptions,
      "selected": _vm.selectedEmployee,
      "placeholder": "Fahrer Wählen"
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo,
      "hasQuickActions": false
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData,
      "size": "small"
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1)])], 1), _c('ChartV2', {
    attrs: {
      "groupByService": "",
      "renderSeparately": "",
      "direction": "horizontal",
      "type": "bar",
      "title": "Einnahmen und Ausgaben",
      "secondValues": _vm.chartComparisonData,
      "values": _vm.chartData,
      "serviceColors": _vm.serviceColors
    }
  }), _c('div', {
    staticClass: "SavingsCalculator-TableContent"
  }, [_c('div', {
    staticClass: "TableSection"
  }, [_c('div', {
    staticClass: "TableSection-Header"
  }, [_c('h3', [_vm._v("Abgerechnete Fahrer")]), _c('div', {
    staticClass: "TableSection-Badge"
  }, [_vm._v(_vm._s(_vm.completedEmployees.length))])]), _vm.completedEmployees.length ? _c('EmployeeTable', {
    staticClass: "SavingsCalculator-Table",
    attrs: {
      "employees": _vm.completedEmployees,
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _c('EmptyState', {
    attrs: {
      "message": "Keine abgerechneten Fahrer gefunden",
      "compact": ""
    }
  })], 1), _c('div', {
    staticClass: "TableSection"
  }, [_c('div', {
    staticClass: "TableSection-Header"
  }, [_c('h3', [_vm._v("Offene Fahrer")]), _c('div', {
    staticClass: "TableSection-Badge"
  }, [_vm._v(_vm._s(_vm.allUnfinishedEmployees.length))])]), _vm.unfinishedEmployees.length ? _c('EmployeeTable', {
    staticClass: "SavingsCalculator-Table",
    attrs: {
      "employees": _vm.allUnfinishedEmployees,
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _c('EmptyState', {
    attrs: {
      "message": "Keine offenen Fahrer gefunden",
      "compact": ""
    }
  })], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }